/* Sidebar styles */
.sidebar {
    height: 100vh; /* Full height */
    width: 250px; /* Initial width of the sidebar */
    position: fixed;
    top: 0; /* Align to top */
    left: 0; /* Align to left */
    background-color: #343a40; /* Set a constant background color */
    overflow-x: hidden;
    transition: width 0.3s ease;
    padding-top: 20px;
    z-index: 1000; /* Ensure the sidebar is above other content */
}

/* Sidebar collapsed state */
.sidebar.collapsed {
    width: 60px; /* Adjust the width as needed */
}

/* Sidebar header styles */
.sidebar-header {
    text-align: center;
    padding: 10px 0;
    color: #fff;
}

/* Navigation links styles */
.list-unstyled.components {
    padding: 30px 10px;
    list-style-type: none;
}

.list-unstyled.components p {
    color: rgba(255, 255, 255, 0.5);
    padding: 10px;
}

.list-unstyled.components li {
    padding: 10px 0;
}

.list-unstyled.components a {
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    height: 20px;
}

.list-unstyled.components a:hover {
    background-color: #007bff;
    border-radius: 8px;
    color: #000000;
}

/* Toggle button styles */
.corner-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.responsive-button {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease, border-radius 0.3s ease;
}

.responsive-button:hover {
    border-radius: 100%;
}

.responsive-button.closed {
    color: black;
    margin: -5px;
}
