/* General luxury link style */
.luxury-link {
    color: #e3c08d; /* Gold text color */
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    padding: 0.5rem 1.5rem;
    border: none;
}

.luxury-link:hover {
    color: #fff;
    background-color: #e3c08d; /* Gold hover background */
    border-radius: 9999px; /* Rounded button style on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

/* Luxury styled button for "Register" */
.luxury-button {
    padding: 0.75rem 2rem;
    background-color: #1c1c1c;
    color: #e3c08d;
    border: 1px solid #e3c08d;
    border-radius: 9999px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
}

.luxury-button:hover {
    background-color: #e3c08d;
    color: #1c1c1c;
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
    .luxury-header ~ h2 {
        margin-top: 60px; /* Adjust as needed */
    }
}