/* General container styling */
.checkout-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    padding-right: 4rem;
    padding-left: 4rem;
    font-family: Arial, sans-serif;
    gap: 2rem;
}

/* For mobile and tablet (screen width <= 768px), stack the checkout-form and product details vertically */
@media (max-width: 768px) {
    .checkout-container {
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
    }

    .product-image {
        width: 100%; /* Make the image responsive */
    }

    .checkout-form {
        max-width: 100%;
    }
}

.product-details {
    text-align: center;
}

.product-image {
    width: 40rem;
    margin: 0 auto 1rem;
}

.product-name {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
}

.product-price {
    font-size: 1.5rem;
    margin-top: 5px;
}

/* Checkout form container */
.checkout-form {
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

/* Checkout Form Header */
.checkout-form h3 {
    font-family: Arial, sans-serif;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: left;
    color: #333;
}

/* Input field styling */
.form-group label {
    font-size: 1rem;
    margin-bottom: 8px;
    display: block;
    color: #333;
    font-weight: 500;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: #0070c9;
    box-shadow: 0 0 3px rgba(0, 112, 201, 0.5);
}

/* Stripe elements input */
.StripeElement {
    padding: 10px 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
}

.StripeElement--focus {
    border-color: #0070c9;
    box-shadow: 0 0 3px rgba(0, 112, 201, 0.5);
}

.StripeElement--invalid {
    border-color: #ff4b4b;
    box-shadow: 0 0 3px rgba(255, 75, 75, 0.5);
}

/* Flexbox for expiry date and CVC fields */
.expiry-cvc-container {
    display: flex;
    justify-content: space-between;
}

.expiry-cvc-container .form-group {
    width: 48%;
}

.checkout-button {
    background-color: #0070c9;
    color: white;
    padding: 15px;
    width: 100%;
    font-size: 1.2rem;
    margin: 20px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #005bb5;
}

/* Error message styling */
.error-message {
    color: #ff4b4b;
    font-size: 1rem;
    margin-top: 10px;
    text-align: left;
}

.fit-content {
    margin-top: 15px;
}